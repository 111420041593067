import React from "react"
import { AuthProvider } from "../../contexts/AuthContext"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import PrivateRoute from "../Auth/PrivateRoute"
import Home from "../Home/Home"
import Login from "../Login/Login"
import ForgetPassword from "../Forget Password/forgetPassword"
import AdminPanel from "../Admin Panel/adminPanel"
import PrivateRouteForAdmin from "../Auth/PrivateRouteForAdmin"


function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={
          <PrivateRoute>
            <Home></Home>
          </PrivateRoute>
        }>
        </Route>
        <Route path="/admin-panel" element={
          <PrivateRouteForAdmin>
            <AdminPanel />
          </PrivateRouteForAdmin>
        }></Route>
        <Route path="/login" Component={Login} />
        <Route path="/forget-password" Component={ForgetPassword} />
      </Routes>
    </AuthProvider>
  )
}

export default App


// import Home from "./components/Home/Home"
// import UpdateProfile from "./components/Update Profile/UpdateProfile"
// import Login from "./components/Login/Login"

// import { AuthProvider } from "./contexts/AuthContext";
// import { useRoutes, BrowserRouter } from "react-router-dom";

// function App() {
//   const routesArray = [
//     {
//       path: "*",
//       element: <Login />,
//     },
//     {
//       path: "/login",
//       element: <Login />,
//     },
//     {
//       path: "/update-profile",
//       element: <UpdateProfile />,
//     },
//     {
//       path: "/home",
//       element: <Home />,
//     },
//   ];
//   let routesElement = useRoutes(routesArray);
//   return (
//       <AuthProvider>
//         <div className="w-full h-screen flex flex-col">{routesElement}</div>
//       </AuthProvider>
//   );
// }

// export default App;