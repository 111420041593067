import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doPasswordReset } from '../../Firebase/Auth'
import { useAuth } from '../../contexts/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { INVALID_EMAIL_MESSAGE, validateEmail } from '../../utils/helper';
import { useStyles } from '../Login/Login';


// TODO remove, this demo shouldn't need to reset the theme.
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function ForgetPassword() {

    const navigate = useNavigate();
    const classes = useStyles();

    const { userLoggedIn }: any = useAuth()

    const [infoMessage, setInfoMessage] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isEmailValid, setIsEmailValid] = React.useState(true)


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data: any = new FormData(event.currentTarget);
        const email = data.get('email');
        if (!isEmailValid) {
            setErrorMessage("Please enter a valid email")
        } else {
            try {
                await doPasswordReset(email)
                setInfoMessage("Email has been sent to you with reset password link")
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Typography component="h1" variant="h4">
                        Forgot password?
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={event => setIsEmailValid(validateEmail(event.target.value)) }
                            helperText={!isEmailValid ? INVALID_EMAIL_MESSAGE : ''}
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!isEmailValid}
                            className={classes.button}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send Email
                        </Button>
                    </Box>
                    <Typography component="h2" variant="subtitle1">
                        Remember your password?  
                    </Typography>
                    <Link href="/login" variant="body2">
                            Login here
                        </Link>
                </Box>
            </Container>
            <Snackbar open={infoMessage.length > 0} autoHideDuration={6000} onClose={() => setInfoMessage("")}>
                <Alert
                    onClose={() => setInfoMessage("")}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {infoMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorMessage.length > 0} autoHideDuration={6000} onClose={() => setErrorMessage("")}>
                <Alert
                    onClose={() => setErrorMessage("")}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}

export default ForgetPassword
