import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { doSignOut } from "../../Firebase/Auth";
import { useAuth } from "../../contexts/AuthContext";
import FormDialog from "../Create User/createUser";

export default function TopNavbar() {

    const { currentUser, isAdmin } = useAuth();

    const [open, setOpen] = React.useState(false);
    const [isCreateUser, setIsCreateUser] = React.useState(false);
    const [isDeleteUser, setIsDeleteUser] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');




    const handleSignOut = () => {
        doSignOut();
    };


    return (
        <>
            <Box sx={{ flexGrow: 1, backgroundColor: "transparent" }}>
                <AppBar
                    position="fixed"
                    style={{ background: "transparent", boxShadow: "none" }}
                >
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            DMVLAX
                        </Typography>
                        <Button color="inherit" href="/">Home</Button>
                        {currentUser && isAdmin && (
                            <>
                            <Button color="inherit" href="/admin-panel">Admin Panel</Button>
                            </>
                        )}
                        <Button color="inherit" onClick={handleSignOut}>
                            Sign out
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
}
