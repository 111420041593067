import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { FileDrop } from 'react-file-drop'
import './uploader.css';
import type { FC } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import PreviewGallery from '../Preview Gallery/PreviewGallery';
import { useContext } from 'react';
import { StateContext, StateContextType } from '../../contexts/state_context';
import { useStyles } from '../Login/Login';
import GoogleDrivePicker from '../picker';
import { Box } from '@mui/material';

type Props = {
    sourceURLs: Array<string>,
    setSourceUrls: (setSourceUrls: Array<string>) => void,
    videoThumbnails: StateContextType['videoThumbnails'],
    setVideoThumbnails: StateContextType['setVideoThumbnails'],
    removeVideo: (index: number) => void,
    setShowEditor: (setShowEditor: boolean) => void,
    currUrlIdx: number,
    setCurrUrlidx: (idx: number) => void,
    splitTimeStamps: StateContextType['splitTimeStamps'],
    setSplitTimeStamps: StateContextType['setSplitTimeStamps'],
    MAX_VIDEO_LIMIT: StateContextType['MAX_VIDEO_LIMIT'],
}

export default function () {
    const fileinputRef = useRef<HTMLInputElement>(null);
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

   // const [openPicker] = useDrivePicker();

    const handleOpenGoogleDrive = () => {
        window.open(process.env.REACT_APP_DMVLAX_GOOGLE_DRIVE_FOLDER_LINK_ID, 'popUpWindow', 'height=400,width=600,left=10,top=10,,scrollbars=yes,menubar=no')
    }

    // const customViewsArray = [new google.picker.DocsView()]; // custom view
    // const handleOpenPicker = () => {
    //     openPicker({
    //         clientId: `${process.env.REACT_APP_DMVLAX_GOOGLE_DEVELOPER_CLIENT_ID}`,
    //         developerKey: `${process.env.REACT_APP_DMVLAX_GOOGLE_DEVELOPER_API_KEY}`,
    //         viewId: "DOCS",
    //         // token: token, // pass oauth token in case you already have one
    //         showUploadView: true,
    //         showUploadFolders: true,
    //         supportDrives: true,
    //         multiselect: true,
    //         // customViews: customViewsArray, // custom view
    //         callbackFunction: (data: any ) => {
    //             if (data.action === 'cancel') {
    //                 console.log('User clicked cancel/close button')
    //             }
    //             //uploadVideo([data])
    //             console.log(data)
    //         },
    //     })
    // }

    const ctx = useContext(StateContext);

    if (ctx === null || ctx === undefined) {
        return null;
    }

    const { sourceURLs, setSourceUrls, videoThumbnails, setVideoThumbnails, removeVideo, setShowEditor, currUrlIdx, setCurrUrlidx, splitTimeStamps, setSplitTimeStamps, MAX_VIDEO_LIMIT }: Props = ctx;

    useEffect(() => {
        document.addEventListener('drop', function (e) {
            e.preventDefault()
            e.stopPropagation()
        })
    }, []);

    const uploadVideo = async (files: (FileList | null )) => {
        const allFiles = Array.from(files ?? []).slice(0, MAX_VIDEO_LIMIT);
        const thumbnails: Array<{ thumbnails: Array<string>, name: string, type: string } | null> = Array(allFiles.length).fill(null);
        const toUpload: Array<string> = allFiles.map((v, i) => {
            const url: string = URL.createObjectURL(v);

            return url;
        });

        setSourceUrls([...sourceURLs, ...toUpload]);
        setSplitTimeStamps([...splitTimeStamps, ...Array(allFiles.length).fill(0).map(_ => [])]);
        setVideoThumbnails([...videoThumbnails, ...thumbnails]);

        for (let i = 0; i < allFiles.length; i++) {
            const thumbnailArray = await generateVideoThumbnails(allFiles[i], 10, 'jpeg');
            thumbnails[i] = { thumbnails: thumbnailArray, name: allFiles[i]?.name ?? `Video ${i + 1}`, type: allFiles[i]?.type };
        }
        setVideoThumbnails([...videoThumbnails, ...thumbnails]);
        setIsLoaded(true)
    }

    return (
        <div className="uploader-preview-box">
            <div className={'uploader-container'}>
                <input
                    onChange={(e) => uploadVideo(e.target.files)}
                    type='file'
                    className='hidden'
                    ref={fileinputRef}
                    accept='video/*'
                />
                {(sourceURLs?.length < 1) && (
                    <FileDrop
                        onDrop={uploadVideo}
                        onTargetClick={() => fileinputRef?.current?.click()}
                    >
                        <div>
                            Click or drop your video here to edit!
                        </div>

                    </FileDrop>
                )}
            </div>

            <PreviewGallery />
            <Box>
                <Container component="main" maxWidth="xs">
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.button}
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleOpenGoogleDrive}
                    >
                        Download from Google Drive
                    </Button>

                </Container>
            </Box>

            {( isLoaded && sourceURLs?.length > 0) && (
                <button className="start-editing-button" onClick={() => setShowEditor(true)}>
                    <div>
                        Start Editing
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </button>
            )}
        </div>
    )
}