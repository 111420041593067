import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doSignInWithEmailAndPassword } from '../../Firebase/Auth'
import { useAuth } from '../../contexts/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import { INVALID_EMAIL_MESSAGE, validateEmail } from '../../utils/helper';


export const useStyles = makeStyles({
  flexGrow: {
    flex: '1',
  },
  button: {
    backgroundColor: ' rgb(10, 132, 255)',
    color: "#ffffff",
    '&:hover': {
      backgroundColor: 'rgb(9, 110, 211)',
      color: "#ffffff",
  },
}})

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" onClick={() => window.open(process.env.REACT_APP_DMVLAX_URL)}>DMVLAX</Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function Login() {

  const navigate = useNavigate();
  const classes = useStyles()

  const { userLoggedIn }: any = useAuth()

  const [isSigningIn, setIsSigningIn] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isEmailValid, setIsEmailValid] = React.useState(true)


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    if (!isEmailValid) {
      setErrorMessage("Please enter a valid email")
    } else if (password.length < 1) {
      setErrorMessage('Please enter a valid password')
    } else {
      try {
        setIsSigningIn(true)
        await doSignInWithEmailAndPassword(email, password)
        navigate("/")
      } catch (e) {
        console.log(e);
        setErrorMessage('Invalid Credentials')
      }
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {userLoggedIn && (<Navigate to={'/'} replace={true} />)}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           <Typography component="h1" variant="h4">
            Welcome to DMVLAX!
          </Typography>
          <br/>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              error={!isEmailValid}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={event => setIsEmailValid(validateEmail(event.target.value)) }
              helperText={!isEmailValid ? INVALID_EMAIL_MESSAGE : ''}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              disabled={!isEmailValid}
              variant="contained"
              className={classes.button}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forget-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Snackbar open={errorMessage.length > 0} autoHideDuration={6000} onClose={() => setErrorMessage("")}>
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default Login
