import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // https://fontawesome.com/v5/docs/web/use-with/react
import { faClosedCaptioning, faLanguage, faLightbulb, faMoon, faPhotoVideo } from '@fortawesome/free-solid-svg-icons' // https://fontawesome.com/v5/docs/web/use-with/react
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import './Home.css';
import Uploader from '../Uploader/Uploader';
import Editor from '../Editor/Editor';
import { StateContext, StateContextType } from '../../contexts/state_context';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { doSignOut } from '../../Firebase/Auth';
import AppBar from '@mui/material/AppBar';
import TopNavbar from '../Navbar/navbar';
import { GoogleAuthProvider } from 'firebase/auth';
import GoogleDrivePicker from '../picker';
import Button from '@mui/material/Button';
import { useStyles } from '../Login/Login';
import { Snackbar } from '@mui/material';

const MAX_VIDEO_LIMIT: number = 1;
const PROGRESSBAR_IMAGES_COUNT: number = 10;

function Home() {
  const [theme, setTheme] = useState<StateContextType['theme']>('dark')
  const [showEditor, setShowEditor] = useState<StateContextType['showEditor']>(false);
  const [sourceURLs, setSourceUrls] = useState<StateContextType['sourceURLs']>([]);
  const [videoThumbnails, setVideoThumbnails] = useState<StateContextType['videoThumbnails']>([]);
  const [currUrlIdx, setCurrUrlidx] = useState<StateContextType['currUrlIdx']>(0);
  const [splitTimeStamps, setSplitTimeStamps] = useState<StateContextType['splitTimeStamps']>([]);
  const [currentTimeStamp, setCurrentTimeStamp] = useState<StateContextType['currentTimeStamp']>({ start: 0, end: 0 });
  const [message, setMessage] = useState<StateContextType['message']>(null);

  const classes = useStyles();
  const handleSignOut = () => {
    doSignOut();
  }
  const removeVideo = (index: number) => {
    if (sourceURLs.length === 0) {
      return;
    }
    else {
      const confirmationDialog = window.confirm("Are you sure you want to delete this video?\nAll your progress will be lost for this video.");
      if (confirmationDialog === true) {
        if (sourceURLs.length === 1) {
          setSourceUrls([]);
          setVideoThumbnails([]);
          setCurrUrlidx(0);
          setShowEditor(false);
          setSplitTimeStamps([]);
          setCurrentTimeStamp({ start: 0, end: 0 })
        }
        else {
          if (currUrlIdx === index && currUrlIdx === (sourceURLs.length - 1)) {
            setCurrUrlidx(index - 1);
          }

          const urls = [...sourceURLs];
          urls.splice(index, 1);
          setSourceUrls(urls);

          const thumbnails = [...videoThumbnails];
          thumbnails.splice(index, 1);
          setVideoThumbnails(thumbnails);

          const splits = [...splitTimeStamps];
          splits.splice(index, 1);
          setSplitTimeStamps(splits);
        }
      }
    }
  }
  return (
    <>
      <TopNavbar />
      <StateContext.Provider value={{ theme, setTheme, showEditor, setShowEditor, sourceURLs, setSourceUrls, currentTimeStamp, setCurrentTimeStamp, videoThumbnails, setVideoThumbnails, currUrlIdx, setCurrUrlidx, removeVideo, splitTimeStamps, setSplitTimeStamps, PROGRESSBAR_IMAGES_COUNT, MAX_VIDEO_LIMIT, message, setMessage }} >
        <div className={`${theme}-theme-bg page-container`}>
          <div className="video-editor-container">
            {/* Add editor here */}
            {showEditor ?
              <Editor />
              :
              <Uploader />
            }
          </div>
        </div>
      </StateContext.Provider>
    </>
  );
}

export default Home;
