import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_DMVLAX_API_KEY,
  authDomain: process.env.REACT_APP_DMVLAX_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DMVLAX_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DMVLAX_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DMVLAX_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DMVLAX_ID,
  measurementId: process.env.REACT_APP_DMVLAX_MEASUREMENT_ID,
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };