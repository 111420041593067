import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doPasswordReset, doCreateUserWithEmailAndPassword, doSendEmailVerification, doSignOut } from '../../Firebase/Auth'
import { useAuth } from '../../contexts/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { generateRandomPassword, INVALID_EMAIL_MESSAGE, validateEmail } from '../../utils/helper';
import { useStyles } from '../Login/Login';


// TODO remove, this demo shouldn't need to reset the theme.
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function CreateUser() {

    const navigate = useNavigate();
    const classes = useStyles();

    const { userLoggedIn, isAdmin }: any = useAuth()

    const [infoMessage, setInfoMessage] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isEmailValid, setIsEmailValid] = React.useState(true)


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data: any = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = generateRandomPassword(8)
        if (!isEmailValid) {
            setErrorMessage("Please enter a valid email")
        } else {
            try {
                await doCreateUserWithEmailAndPassword(email, password)
                await doPasswordReset(email);
                await doSignOut();
                setInfoMessage("Email has been sent to user with reset password link")
            } catch (e) {
                setErrorMessage("Can't create user. User may already exist")
            }
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            {userLoggedIn && isAdmin && (
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <Typography component="h1" variant="h4">
                            Create User
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                error={!isEmailValid}
                                helperText={!isEmailValid ? INVALID_EMAIL_MESSAGE : ''}
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={event => setIsEmailValid(validateEmail(event.target.value))}
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                disabled={!isEmailValid}
                                variant="contained"
                                className={classes.button}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Container>
            )}
            <Snackbar open={infoMessage.length > 0} autoHideDuration={6000} onClose={() => setInfoMessage("")}>
                <Alert
                    onClose={() => setInfoMessage("")}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {infoMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorMessage.length > 0} autoHideDuration={6000} onClose={() => setErrorMessage("")}>
                <Alert
                    onClose={() => setErrorMessage("")}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}

export default CreateUser
