import * as React from 'react';
import { faPause, faPlay, faRemove, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toolkit from '@mui/material/Tooltip';
import { useState } from 'react';
import { useRef } from 'react';
import "./editor.css";
import PreviewGallery from '../Preview Gallery/PreviewGallery';
import { StateContext, StateContextType } from '../../contexts/state_context';
import { useEffect } from 'react';
import Progressbar from '../Progress Bar/Progressbar';
import croppedsection from '../../assets/croppedsection.svg';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg' // https://github.com/ffmpegwasm/ffmpeg.wasm/blob/master/docs/api.md
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, ThemeProvider } from '@mui/material';
import { formatTime, isValidTimeFormat, timeToSeconds } from '../../utils/helper';
import { darkTheme } from '../Login/Login';

type Props = {
    sourceURLs: Array<string>,
    videoThumbnails: StateContextType['videoThumbnails'],
    setVideoThumbnails: StateContextType['setVideoThumbnails'],
    removeVideo: (index: number) => void,
    currUrlIdx: number,
    setCurrUrlidx: (idx: number) => void,
    setSourceUrls: (setSourceUrls: Array<string>) => void,
    splitTimeStamps: StateContextType['splitTimeStamps'],
    setSplitTimeStamps: StateContextType['setSplitTimeStamps'],
    currentTimeStamp: StateContextType['currentTimeStamp'],
    setCurrentTimeStamp: StateContextType['setCurrentTimeStamp'],
    setMessage: StateContextType['setMessage'],
}

var throttle = require('lodash/throttle');

export default function () {

    useEffect(() => {
        const unloadCallback = (event: Event) => {
            event.preventDefault();
            event.returnValue = false;
            return null;
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    //Ref to handle the current instance of ffmpeg when loaded
    const ffmpeg: any = useRef(null)


    //Function handling loading in ffmpeg
    const load = async () => {
        try {
            await ffmpeg.current.load()
        }
        catch (error) {
            console.log(error)
        }
    }

    //Loading in ffmpeg when this component renders
    useEffect(() => {
        ffmpeg.current = createFFmpeg({
            log: true,
            corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js'
        })
        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ctx = React.useContext(StateContext);

    if (ctx === null || ctx === undefined) {
        return null;
    }

    const { sourceURLs, videoThumbnails, removeVideo, currUrlIdx, setSourceUrls, setVideoThumbnails, setCurrUrlidx, splitTimeStamps, setSplitTimeStamps, setMessage, currentTimeStamp, setCurrentTimeStamp }: Props = ctx;

    const videoRef = useRef<HTMLVideoElement>(null);
    const playBackBarRef = useRef<HTMLDivElement>(null);
    const progressBarRef = useRef<HTMLDivElement>(null);


    const [isPortrait, setIsPortrait] = useState<boolean>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [isMessageShown, setIsMessageShown] = useState<boolean>(false);
    const [seekTime, setSeekTime] = useState<{ time: string, offset: number } | null>(null);

    const [infoMessage, setInfoMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [startTimeErrorMessage, setStartTimeErrorMessage] = useState<string>("");
    const [endTimeErrorMessage, setEndTimeErrorMessage] = useState<string>("");

    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [isStartTimeValid, setIsStartTimeValid] = useState<boolean>(false);
    const [isEndTimeValid, setIsEndTimeValid] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<any>(null)
    const [endTime, setEndTime] = useState<any>(null)
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [isPlaying, setIsPlaying] = useState<boolean>(videoRef?.current?.paused ?? false);

    const [isAddCroppedSection, setIsAddCroppedSection] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setStartTime(null);
        setEndTime(null)
        setStartTimeErrorMessage('');
        setEndTimeErrorMessage('');
        setIsStartTimeValid(false);
        setIsEndTimeValid(false);
        setOpen(false);
    };

    const handleLoadedVideo = (videoElement: any) => {
        if (videoElement) {
            setIsLoaded(true)
        }
        if (videoElement.clientHeight > videoElement.clientWidth) {
            setIsPortrait(true);
        }
        else {
            setIsPortrait(false);
        }

        if (splitTimeStamps[currUrlIdx].length === 0) {
            const splitVideo = [...splitTimeStamps];
            splitVideo[currUrlIdx].push({ start: 0, end: videoElement.duration });
            setSplitTimeStamps(splitVideo);
        }
    }

    function getVideoDuration(durationInSeconds: number) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    const getSeekTimeFromOffset = (offset: number) => {
        if (playBackBarRef?.current && videoRef?.current) {
            const playbackRect: DOMRect = playBackBarRef.current.getBoundingClientRect();
            const seekTime = ((offset - playbackRect.left) / playbackRect.width) * videoRef.current.duration;
            return seekTime;
        }
        return 0;
    }

    const displayVideoSeekTime = throttle((offset: number) => {
        if (playBackBarRef?.current && videoRef?.current) {
            const playbackRect: DOMRect = playBackBarRef.current.getBoundingClientRect();
            const seekTime = ((offset - playbackRect.left) / playbackRect.width) * videoRef.current.duration;
            const time = getVideoDuration(seekTime);
            setSeekTime({ time, offset: (offset - playbackRect.left) - 35 });
        }
    }, 500, { leading: false });


    const setPlayPause = (always: 'play' | 'pause' | null = null): void => {
        if (always === null) {
            if (isPlaying) {
                videoRef?.current?.pause();
            }
            else {
                videoRef?.current?.play();
            }
            setIsPlaying(!isPlaying);
        }
        else if (always === 'play') {
            videoRef?.current?.play();
            setIsPlaying(true);
        }
        else {
            videoRef?.current?.pause();
            setIsPlaying(false);
        }
    }

    const updateProgress = (offset: any) => {
        const seekTime = getSeekTimeFromOffset(offset);
        videoRef?.current?.pause();
        setIsPlaying(false);
    }
    const handleTimeUpdate = () => {
        if (currentTimeStamp.end !== 0 && videoRef?.current?.currentTime.toFixed() === currentTimeStamp?.end.toFixed()) {
            setPlayPause('pause');
            videoRef.current.currentTime = currentTimeStamp.start;
        }
    }

    const handleReset = () => {
        if (videoRef && videoRef.current && videoRef.current.currentTime > 0) {
            const resetObj = { start: 0, end: videoRef.current.duration }
            videoRef.current.currentTime = 0;
            setCurrentTimeStamp(resetObj)
            const timestamps: any = [...splitTimeStamps];
            timestamps[currUrlIdx] = [];
            timestamps[currUrlIdx].push(resetObj)
            setSplitTimeStamps(timestamps)
        }
    }

    const handleDeleteSection = () => {
        if (videoRef && videoRef.current) {
            const resetObj = { start: 0, end: videoRef.current.duration }
            if (splitTimeStamps[currUrlIdx].length > 1) {
                const timestamps: any = [...splitTimeStamps];
                timestamps[currUrlIdx].pop();
                setSplitTimeStamps(timestamps)
            } else if (splitTimeStamps[currUrlIdx].length === 1) {
                handleReset();
            }
        }
    }

    const saveVideo = async () => {

        if (!isDisabled) {
            const videos = splitTimeStamps[0];
            setIsDisabled(true);
            setInfoMessage("Video processing in progress, your download will begin automatically upon completion.")
            for (let i = 0; i < videos.length; i++) {
                const { start, end } = videos[i];
                const duration = end - start;
                const fileName = videoThumbnails[currUrlIdx]?.name.split('.')[0];
                try {
                    await ffmpeg.current.FS('writeFile', 'myFile.mp4', await fetchFile(sourceURLs[0]))
                    await ffmpeg.current.run('-ss', `${start}`, '-accurate_seek', '-i', 'myFile.mp4', '-to', `${duration}`, '-codec', 'copy', `${fileName}_clip_${i}.mp4`)

                    const data = ffmpeg.current.FS("readFile", `${fileName}_clip_${i}.mp4`);
                    const trimmedVideoBlob = new Blob([data.buffer], { type: "video/mp4" });
                    const trimmedVideoURL = URL.createObjectURL(trimmedVideoBlob);

                    const downloadLink = document.createElement("a");
                    downloadLink.href = trimmedVideoURL;
                    downloadLink.download = `${fileName}_clip_${i}.mp4`;
                    downloadLink.click();
                    if (i === videos.length - 1) {
                        setIsDisabled(false);
                        setTimeout(() => {
                            setInfoMessage("")
                            setIsMessageShown(true)
                        }, 5000)
                    }
                } catch (e) {
                    setErrorMessage("Something went wrong while downloading the video. Please try again!")
                    setIsDisabled(false)
                }
            }
        }
    };
    const handleStartTimeInput = (time: any) => {
        if (isValidTimeFormat(time)) {
            const timeInSeconds: any = timeToSeconds(time);
            if (timeInSeconds && timeInSeconds >= 0 && videoRef && videoRef.current && videoRef.current.duration && timeInSeconds <= videoRef.current.duration) {
                setStartTime(timeInSeconds)
                setIsStartTimeValid(true);
                setStartTimeErrorMessage('')
                if(endTime && timeInSeconds < endTime) {
                    setIsEndTimeValid(true);
                    setEndTimeErrorMessage('')
                } else if( endTime && timeInSeconds >= endTime) {
                    setIsStartTimeValid(false);
                    setStartTimeErrorMessage('Invalid Duration')
                }
            } else {
                setStartTimeErrorMessage('Invalid Duration')
                setIsStartTimeValid(false)
            }
        } else {
            setStartTimeErrorMessage('Invalid format')
            setIsStartTimeValid(false)
        }
    }
    const handleEndTimeInput = (time: any) => {
        if (time && isValidTimeFormat(time)) {
            const timeInSeconds: any = timeToSeconds(time);
            if (timeInSeconds && timeInSeconds >= 0 && videoRef && videoRef.current && videoRef.current.duration && timeInSeconds <= videoRef.current.duration) {
                setEndTime(timeInSeconds)
                setIsEndTimeValid(true);
                setEndTimeErrorMessage('')
                if(startTime && timeInSeconds > startTime) {
                    setIsStartTimeValid(true);
                    setStartTimeErrorMessage('');
                } else if(startTime && timeInSeconds <= startTime){
                    setIsEndTimeValid(false);
                    setEndTimeErrorMessage("Invalid duration")
                }
            } else {
                setIsEndTimeValid(false)
                setEndTimeErrorMessage('Invalid duration')
            }
        } else {
            setEndTimeErrorMessage('Invalid format')
            setIsEndTimeValid(false)
        }
    }

    const isDurationValid = () => {
        if (isStartTimeValid && isEndTimeValid) {
            return true
        }
        return false
    }
    const cutAndSaveVideo = async (start: any, end: any) => {
        if (!isDisabled) {
            setIsDisabled(true);
            setInfoMessage("Video processing in progress, your download will begin automatically upon completion.")
            const duration = end - start;
            const fileName = videoThumbnails[currUrlIdx]?.name.split('.')[0];
            try {
                await ffmpeg.current.FS('writeFile', 'myFile.mp4', await fetchFile(sourceURLs[0]))
                await ffmpeg.current.run('-ss', `${start}`, '-accurate_seek', '-i', 'myFile.mp4', '-to', `${duration}`, '-codec', 'copy', `${fileName}_shortclip.mp4`)

                const data = await ffmpeg.current.FS("readFile", `${fileName}_shortclip.mp4`);
                const trimmedVideoBlob = new Blob([data.buffer], { type: "video/mp4" });
                const trimmedVideoURL = URL.createObjectURL(trimmedVideoBlob);

                const downloadLink = document.createElement("a");
                downloadLink.href = trimmedVideoURL;
                downloadLink.download = `${fileName}_shortclip.mp4`;
                downloadLink.click();
                setIsDisabled(false);
                setTimeout(() => {
                    setInfoMessage("")
                    setIsMessageShown(true)
                }, 5000)
            } catch (e) {
                setIsDisabled(false)
                setErrorMessage("Something went wrong while downloading the video. Please try again!")
            }
        }
    }


    return (
        <div>
            <h3 style={{ textAlign: 'center' }}>{videoThumbnails[currUrlIdx]?.name}</h3>
            <div className='video-editor-container' key={`${videoThumbnails[currUrlIdx]?.name}-${currUrlIdx}-video`}>
                <video
                    ref={videoRef}
                    onLoadedMetadata={(event) => handleLoadedVideo(event.target)}
                    className={`video-element ${isPortrait ? 'portrait' : 'landscape'}`}
                    muted={isMuted}
                    controls={true}
                    onClick={() => setPlayPause(null)}
                    onEnded={() => setPlayPause('pause')}
                    onTimeUpdate={handleTimeUpdate}
                    key={`${videoThumbnails[currUrlIdx]?.name}-${currUrlIdx}-video-element`}
                >
                    <source src={sourceURLs[currUrlIdx]} type='video/mp4' />
                </video>
                {isLoaded &&  currentTimeStamp && currentTimeStamp.start && currentTimeStamp.end && videoRef && videoRef.current && videoRef.current.duration > 0 && (
                <div className='timeline-container'>
                    <div className='inner-div'>
                        Start: {' '} {formatTime(currentTimeStamp.start)}
                    </div>
                    <div className='inner-div'>
                        End: {' '} {currentTimeStamp.end === 0 ? formatTime(videoRef.current?.duration) : formatTime(currentTimeStamp.end)}
                    </div>
                </div>
                )}

                <div className="progressbar-container">
                    {seekTime !== null && (
                        <div style={{ left: seekTime?.offset }} className='seektime'>
                            {seekTime?.time}
                        </div>
                    )}

                    <Progressbar videoRef={videoRef} setPlayPause={setPlayPause} isAddCroppedSection={isAddCroppedSection} setIsAddCroppedSection={setIsAddCroppedSection} />
                </div>

                {isLoaded && videoRef && videoRef.current && videoRef.current.duration > 0 && (
                    <>

                        <div className='controls'>
                            <div className="controls-group">
                                <button className='control-btn normal-control-btn' title='Mute/Unmute Video' onClick={() => setIsMuted(!isMuted)}>{isMuted ? <FontAwesomeIcon icon={faVolumeMute} className='control-icon' /> : <FontAwesomeIcon icon={faVolumeUp} className='control-icon' />}</button>
                            </div>
                            <div className="controls-group">
                                <button className='control-btn normal-control-btn' title='Play/Pause Video' onClick={() => setPlayPause(null)}>{isPlaying ? <FontAwesomeIcon icon={faPause} className='control-icon' /> : <FontAwesomeIcon icon={faPlay} className='control-icon' />}</button>
                            </div>
                            <div className="controls-group">
                                <button className={`control-btn ${isAddCroppedSection ? 'highlited-control-icon' : 'normal-control-btn'}`} title='Add Section'
                                    onClick={
                                        isAddCroppedSection ?
                                            () => {
                                                setIsAddCroppedSection(false);
                                                setInfoMessage("");
                                            }
                                            :
                                            () => {
                                                setIsAddCroppedSection(true);
                                                setInfoMessage('Click on any unselected part of the progressbar to add a new cropped section.')
                                            }}>
                                    {isAddCroppedSection ? <FontAwesomeIcon icon={faRemove} className='control-icon' style={{ color: '#FF3333' }} /> : <img src={croppedsection} className='control-icon' />}
                                </button>
                            </div>
                            <div className="controls-group">
                                <button className='control-btn normal-control-btn' title='Manually Cut and Download' onClick={() => handleClickOpen()}>Cut</button>
                            </div>
                            <div className="controls-group">
                                <button className='control-btn normal-control-btn' title='Reset Settings' onClick={() => handleReset()}>reset</button>
                            </div>
                            <div className="controls-group">
                                <button className='control-btn normal-control-btn' title='Delete Section' onClick={() => handleDeleteSection()}>Delete</button>
                            </div>
                            <div className="controls-group">
                                <button className='control-btn normal-control-btn' title='Download Selected Parts' onClick={() => { setIsMessageShown(false); saveVideo() }}>Download</button>
                            </div>
                        </div>
                    </>
                )}
                <ThemeProvider theme={darkTheme}>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                            onSubmit: async (event: any) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                const formJson = Object.fromEntries(formData.entries());
                                const start = timeToSeconds(formJson.start);
                                const end = timeToSeconds(formJson.end);
                                await cutAndSaveVideo(start, end);
                                handleClose();
                            },
                        }}
                    >
                        <DialogTitle>Cut and download video</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter duration in HH:MM:SS fomrat
                            </DialogContentText>
                            <TextField
                                autoFocus
                                error={startTimeErrorMessage.length > 0}
                                onChange={event => handleStartTimeInput(event.target.value)}
                                required
                                margin="dense"
                                id="name"
                                name="start"
                                label="From"
                                type="start"
                                helperText={startTimeErrorMessage}
                            />
                            {' '}
                            <TextField
                                required
                                error={endTimeErrorMessage.length > 0}
                                onChange={event => handleEndTimeInput(event.target.value)}
                                margin="dense"
                                id="name"
                                name="end"
                                label="To"
                                type="end"
                                helperText={endTimeErrorMessage}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit" disabled={!isDurationValid()}>Download</Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>

                <Snackbar open={!isMessageShown && infoMessage.length > 0} autoHideDuration={5000} onClose={() => { setInfoMessage(""); setIsMessageShown(true) }}>
                    <Alert
                        onClose={() => { setInfoMessage(""); setIsMessageShown(true) }}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {infoMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={errorMessage.length > 0} autoHideDuration={5000} onClose={() => setErrorMessage("")}>
                    <Alert
                        onClose={() => setErrorMessage("")}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}