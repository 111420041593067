export const validateEmail = (email) => {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the pattern
    return emailPattern.test(email);
}
export const  generateRandomPassword = (length) => {
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var password = "";
    for (var i = 0; i < length; i++) {
        var randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
}

export const isValidTimeFormat = (input) => {
    const regex = /^(?:[0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(input);
  }

  export const timeToSeconds = (timeString) => {
    const timeParts = timeString.split(':');
    if (timeParts.length !== 3) {
      // If the format is incorrect, return null or throw an error
      return null;
    }
  
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
  
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      // If any part is not a valid number, return null or throw an error
      return null;
    }
  
    return hours * 3600 + minutes * 60 + seconds;
  }
export const formatTime = (seconds) => {
    var hours = Math.floor(seconds / 3600).toFixed();
    var minutes = Math.floor((seconds % 3600) / 60).toFixed();
    var remainingSeconds = (seconds % 60).toFixed();
  
    return (
      (hours < 10 ? '0' : '') + hours + ':' +
      (minutes < 10 ? '0' : '') + minutes + ':' +
      (remainingSeconds < 10 ? '0' : '') + remainingSeconds
    );
  }

export const INVALID_EMAIL_MESSAGE='Invalid email'